@import "../../styles/variables";

.h-app-logo {
  flex: 0 0 68px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: $app-logo-margin;
  height: 68px;
  text-decoration: none;
  transition: all 0.2s;

  .app-logo {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 8px;

    .logo-image {
      display: block;
      height: 50px;
      width: 50px;
      background-size: contain;
      margin-left: -25px;
    }

    .logo-text {
      font-size: 20px;
      color: #626262;
      font-weight: 400;
    }
  }

  &:hover {
    opacity: 0.5;
  }
}

@import "../../styles/variables";

.h-map-view-page-map-wrapper {
  &.single-point {
    position: relative;
    top: unset;
    left: unset;
    bottom: unset;
    right: unset;
  }

  .changed-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .map-button-container {
    position: absolute;
    top: $component-padding;
    left: $component-padding;
    display: flex;
    flex-direction: row;

    .map-button {
      overflow: hidden;
      margin-right: $component-gutter;
      background: $item-Background;
      border-radius: $box-border-radius;
      box-shadow: $layer-shadow;

      &:hover {
        background: #eee;
      }

      button {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center;
        height: 34px;
        width: 34px;
        font-size: 20px;
        box-sizing: border-box;
      }
    }
  }
}

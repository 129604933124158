@import "../../styles/variables";

.h-app-page-with-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .awh-heading {
    flex: 1;
    background: $page-with-header-Background;
    color: $page-with-header-Foreground;
    border-top-left-radius: $box-border-radius;
    border-top-right-radius: $box-border-radius;
    padding: $component-padding;

    h1 {
      margin: 0;
      font-size: 16px;
    }

    .awh-subtitle {
      display: inline-flex;
      margin: $component-padding $component-padding 0 0;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: $box-border-radius;
      padding: $component-gutter $component-padding $component-gutter
        $component-gutter;

      &.focus-1 {
        background-color: rgba(0, 0, 0, 0.2);
      }

      svg {
        margin: 0 10px 0 5px;
        font-size: 20px;
        align-self: center;
      }

      div {
        label {
          display: block;
          font-size: 11px;
          margin-bottom: 5px;
        }

        span {
          display: block;
          font-size: 13px;
          font-weight: 700;
        }
      }
    }
  }

  .awh-content {
    flex: 99;
    position: relative;
  }
}

@import "../../styles/variables";

.h-app-breadcrumb-trail {
  display: block;
  position: relative;
  margin: 0 0 0 ($sideNav-width);
  right: 0;
  top: 0;
  padding: calc($component-padding / 2) $component-padding;
  line-height: 20px;
  font-size: 12px;
  box-sizing: border-box;
  font-weight: 500;
  text-transform: uppercase;
  flex: none;
  transition: margin-left 0.2s;

  a,
  span {
    font-size: 12px !important;
    font-family: Arial, Helvetica, sans-serif !important;
  }

  & > span {
    padding: 5px;
    float: left;
  }

  & > a {
    float: left;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    background: $breadcrumb-Background;
    color: $breadcrumb-Foreground;

    &:hover {
      background: $breadcrumb-hover-Background;
      color: $breadcrumb-hover-Foreground;
    }

    button {
      padding: 5px !important;
      text-transform: uppercase !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
      letter-spacing: inherit !important;
    }
  }

  svg {
    float: left;
    height: 10px;
    margin: 10px 3px;
  }
}

.h-app-side-nav.collapsed + .h-app-breadcrumb-trail {
  margin-left: 15px + $component-padding;
}

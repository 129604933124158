@import "../../styles/variables";

.measurable-group-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: solid 2px $horizontalRule-Background;

  &.moved {
    animation: move-flash 1.5s forwards;
  }

  .measurable-group {
    width: 100%;

    .field-chips {
      padding: 10px 0 0 0;
      margin-bottom: $field-gutter;
      display: flex;
      flex-direction: row;

      .fields {
        background-color: $field-instructions-Background;
        border-radius: $box-border-radius;
        width: 100%;
        padding-bottom: 5px;
        padding-left: 5px;
      }
    }
  }

  .action-section {
    margin-left: $component-gutter;

    .action-button {
      height: 40px;
      max-width: 25px;
      min-width: 25px !important;
    }
  }

  .measurable-chips {
    padding: 5px;
  }
}

.measurable-group-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.measurable-group-editor-modal {
  .no-selected-form-fields {
    text-align: center;
    background-color: $no-data-Background;
    color: $no-data-Foreground;
    border-radius: $box-border-radius;
    padding: $component-padding;
    margin-bottom: 3px;
  }

  .selected-form-field {
    display: flex;
    flex-direction: row;
    border-bottom: dashed 1px $horizontalRule-Background;

    &.moved {
      animation: move-flash 1.5s forwards;
    }

    .h-app-input-field {
      margin-top: 0 !important;
    }

    .s-icon {
      flex: 0 0 30px;
      align-self: center;
      font-size: 20px;
      display: flex;
      flex-direction: row;
      padding-top: 4px;
    }

    .s-name {
      flex: 1;
      margin-right: $field-gutter;
      align-self: center;
    }

    & > label {
      flex: 0 0 120px;
      margin-right: $field-gutter;
    }

    button {
      border-radius: 50% !important;
      align-self: center;
      height: 30px;
      flex: 0 0 30px;
      min-width: unset;
      padding: 0 !important;
      color: $app-primary;

      &:disabled {
        color: $page-Foreground;
        opacity: 0.2;
      }

      &.delete {
        color: $field-instructions-warning-Foreground;
      }

      &:hover {
        background-color: $library-item-hover-Background;
      }

      svg {
        margin: 0;
      }
    }
  }

  .add-form-field {
    display: flex;
    flex-direction: row;
    margin-top: $field-gutter;
    margin-bottom: $component-padding;

    .h-app-input-field {
      margin-top: 0 !important;
    }

    & > div {
      flex: 1;
      margin-right: $field-gutter;
    }

    & > label {
      flex: 0 0 120px;
      margin-right: $field-gutter;
    }

    & > button {
      flex: 0 0 90px;
      height: 40px;
      align-self: flex-start;
    }
  }
}

.measurable-chip {
  margin: $component-gutter $component-gutter 0 0;

  svg {
    font-size: 20px;
    background-color: rgba($color: #00afef, $alpha: 0.3);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 6px 5px 6px 6px;
    margin-left: 0px !important;
  }
}

.descriptor-chip {
  margin: $component-gutter $component-gutter 0 0;

  svg {
    font-size: large;
    background-color: rgba($color: #22bb77, $alpha: 0.3);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 7px 5px 7px 6px;
    margin-left: 0px !important;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
}

.measurables-button-bar {
  padding-top: $component-padding;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .error-button {
    background-color: red !important;
  }
}

.adornment-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 51px;
  color: $horizontalRule-Background;

  .measurable-group-action-button {
    margin: -20px 0;

    button {
      height: 30px;
      width: 30px;
      font-size: 20px;
      justify-content: center;
      align-items: center !important;
      border-radius: 50%;
      cursor: pointer !important;
      transition: all 0.2s;

      & * {
        cursor: pointer !important;
      }

      &:hover {
        background-color: $library-item-hover-Background;
      }
    }

    .edit-action {
      color: $app-primary;
    }

    .delete-action {
      color: $field-instructions-warning-Foreground;
    }
  }
}

.add-measurable-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  color: white;

  svg {
    margin-right: 5px;
  }
}

@import "../../styles/variables";

.h-app-tabs-container {
  border-radius: $box-border-radius $box-border-radius;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  .h-app-tab-bar {
    display: flex;
    flex-direction: row;
    position: relative;
    flex: 1;

    &.overflow {
      .tab-scroll-left,
      .tab-scroll-right {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        background-color: $window-Background;
        border-left: solid 1px $horizontalRule-Background;
        display: flex;
        flex-direction: column;

        button {
          flex: 1;
          position: relative;

          svg {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -8px 0 0 -8px;
            font-size: 16px;
            opacity: 0.6;
          }
        }
      }

      .tab-scroll-left {
        right: 40px;
      }

      .scroll-button-placeholder {
        min-width: 80px;
      }
    }

    .bottom-border {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: $horizontalRule-Background;
    }

    .scroll-placeholder {
      position: relative;
      width: 0;
      display: block;
      margin-left: 0;
    }

    .h-app-tab {
      padding: $page-padding;
      text-transform: uppercase;
      position: relative;
      white-space: nowrap;

      i {
        display: block;
        position: absolute;
        bottom: 0;
        height: 2px;
        background: $clickable-gradient;
        transition: left 0.3s, right 0.3s, opacity 0.4s;
        left: 50%;
        right: 50%;
        opacity: 0;

        &.selected {
          left: 0;
          right: 0;
          opacity: 1;
        }
      }
    }
  }

  .h-app-tab-content {
    flex: 99;
    position: relative;

    .tab-content {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &.selected {
        display: block;
      }
    }
  }
}

@keyframes selected-tab-border-in {
  0% {
    left: 50%;
    right: 50%;
    opacity: 0;
  }
  50% {
    left: -3px;
    right: -3px;
    opacity: 1;
  }
  100% {
    left: 0;
    right: 0;
  }
}

@keyframes selected-tab-border-out {
  100% {
    left: 0;
    right: 0;
    opacity: 1;
  }
  100% {
    left: 50%;
    right: 50%;
    opacity: 0;
  }
}

@import "../../styles/variables";

.type-field-readonly {
  width: 100%;
  margin-bottom: -5px;
  margin-top: 23px;

  .MuiOutlinedInput-notchedOutline {
    border-width: 2px !important;
    border-color: $field-readonly-Border !important;
  }
}

.type-field {
  width: 100%;
  margin-bottom: -5px;
  margin-top: 23px;

  .MuiOutlinedInput-notchedOutline {
    border-width: 2px !important;
    border-color: rgba($color: #000000, $alpha: 0.2) !important;
  }
}

.option-chip {
  margin-right: 5px;
  margin-bottom: 5px;
}

.option-chip-readonly {
  margin-top: 20px;
  margin-right: 5px;
}

@import "../../styles/variables";

.chart-type-selector {
  margin: $field-gutter 0 $component-padding 0;

  h3 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    margin: 0 0 5px 0;
    color: rgba(0, 0, 0, 0.6);
  }

  .chart-type-option {
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 3px;
    font-family: inherit;
    font-size: 16px;

    button {
      font-family: inherit;
      font-size: 16px;
      cursor: pointer;
    }

    .chart-type-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 5px;
      margin: 3px;
      transition: all 0.4s;
      box-sizing: border-box;

      svg {
        font-size: 19px;
        transition: all 0.2s;
      }
    }

    label {
      line-height: 29px;
      padding: 0 3px;
      margin: 0 3px 0 0;
    }

    button label {
      margin-top: 3px;
      cursor: pointer;
    }

    &.selected {
      .chart-type-icon {
        background-color: $app-primary;

        svg {
          color: #fff;
        }
      }

      label {
        color: $app-primary-dark;
      }
    }
  }
}

.chart-area-band-config,
.chart-guide-config {
  display: flex;
  flex-direction: row;

  .h-app-input-field,
  .h-app-autocomplete-field {
    flex: 1;

    button {
      min-width: unset;
      margin-top: unset;
      margin-left: unset;
    }

    &.fixed-width-minmax {
      flex: 0 0 90px;
    }

    &.fixed-width-style {
      flex: 0 0 110px;
    }

    &.fixed-width-color {
      flex: 0 0 145px;
    }

    &:not(:first-child) {
      margin-left: $component-padding;
    }
  }
}

.h-app-tool-button.chart-preview-button {
  position: absolute;
  right: $component-padding;
  padding: 0 !important;
  height: 43px;
}

.h-app-modal.chart-preview-modal {
  height: 875px;
  width: 1400px;
  margin: -437px 0 0 -700px;
}

.label-selector-with-format-example {
  flex-wrap: wrap;

  span {
    display: block;
    flex: 0 0 100%;

    &:last-child {
      font-size: 0.8em;
      opacity: 0.5;
    }
  }
}

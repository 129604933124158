@import "../../styles/variables";

.h-app-library-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: $library-width;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .h-app-library-card {
    background: $library-item-Background;
    color: $library-item-Foreground;
    border-bottom: solid 1px $horizontalRule-Background;
    display: block;
    position: relative;

    button {
      display: block !important;
      text-align: left;
      padding: $component-padding !important;
    }

    &:hover {
      background: $library-item-hover-Background;
      color: $library-item-hover-Foreground;
    }

    &.active {
      background: $library-item-active-Background;
      color: $library-item-active-Foreground;
    }

    .card-primary {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      line-height: 18px;
      margin-right: $component-padding + 10px;
    }

    .card-secondary {
      display: block;
      font-size: 11px;
      line-height: 18px;
      opacity: 0.5;

      &.bold {
        opacity: 0.7;
        font-weight: 700;
      }
    }

    .card-version {
      position: absolute;
      top: $component-padding + 3px;
      right: $component-padding + 8px;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .no-data-library-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    opacity: 0.7;

    svg {
      font-size: 40px;
      margin: 30px 0 15px 0;
      opacity: 0.5;
    }
  }

  .h-app-library {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: $library-Background;
    border-radius: $box-border-radius;
    box-shadow: $layer-shadow;
    overflow: hidden;
    animation: $animation-slide-in;

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
    }

    & > h1 {
      font-size: 14px;
      text-transform: uppercase;
      margin: 0;
      padding: $component-padding;
      border-bottom: solid 1px $horizontalRule-Background;
    }

    .pager {
      border-top: solid 1px $horizontalRule-Background;
      padding: $component-padding;
      background: $paging-Background;

      & > ul {
        justify-content: center !important;

        .Mui-selected {
          background: $paging-selected-Background !important;
          color: $paging-selected-Foreground !important;
        }
      }
    }
  }

  & > h1 {
    font-size: 0.8em;
    margin: 0 0 $component-padding $component-padding / 2;
    padding: 0;
    font-weight: 500;
    animation: $animation-slide-in;
  }

  .h-app-incomplete-library {
    flex: 1;
    max-height: 25%;
    display: flex;
    flex-direction: column;
    background: $library-Background;
    box-sizing: border-box;
    border-radius: $box-border-radius;
    box-shadow: $layer-shadow;
    overflow: hidden;
    animation: $animation-slide-in;
    margin-bottom: $component-padding;
  }
}

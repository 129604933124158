@import "../../styles/variables";

$modalCoverColor: rgba(0, 0, 0, 0.5);
$modalTransitionTime: 0.5s;

.h-app-modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  animation: modal-cover-in $modalTransitionTime forwards;

  .h-app-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 800px;
    height: 500px;
    margin: -250px 0 0 -400px;
    background: $modal-window-Background;
    box-shadow: $layer-shadow;
    border-radius: $box-border-radius;
    animation: modal-in $modalTransitionTime forwards;
    display: flex;
    flex-direction: column;
    transition: filter 0.3s;

    &.modal-full-screen {
      top: $component-padding;
      bottom: $component-padding;
      left: $component-padding;
      right: $component-padding;
      height: unset;
      width: unset;
      margin: unset;
    }

    .modal-title {
      flex: 1;
      min-height: 40px;
      margin: 0;
      padding: 0 0 0 $page-padding;
      font-size: 12px;
      line-height: 40px;
      font-weight: 500;
      text-transform: uppercase;

      .close-modal {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
          background: $button-secondary-hover-Background;
        }
      }
    }

    .modal-body {
      flex: 100;
      margin: 0 $page-padding;
      box-shadow: $layer-shadow;
      border-radius: $box-border-radius;
      background: $modal-content-Background;
      position: relative;
    }

    .modal-actions {
      flex: 1;
      min-height: 40px;
      text-align: right;
      margin: $component-padding $page-padding $component-padding 0;

      & > * {
        margin-left: $component-gutter;
      }
    }
  }

  &.has-alert {
    .h-app-modal {
      filter: blur(10px);
    }
  }

  &.closing {
    animation: modal-cover-out $modalTransitionTime forwards;

    .h-app-modal {
      animation: modal-out $modalTransitionTime forwards;
    }
  }
}

@keyframes modal-cover-in {
  0% {
    background: transparent;
  }
  100% {
    background: $modalCoverColor;
  }
}

@keyframes modal-cover-out {
  0% {
    background: $modalCoverColor;
  }
  100% {
    background: transparent;
  }
}

@keyframes modal-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modal-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

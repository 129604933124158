@import "../../styles/variables";

.h-app-material-picker {
  .no-material {
    box-sizing: border-box;
    display: block;
    background-color: #ffffff;
    border: solid 1px #000000;
    height: 20px;
    flex: 0 0 20px;
    margin-right: $component-gutter;
    border-radius: $box-border-radius;
  }

  .preview {
    height: 20px;
    flex: 0 0 20px;
    border: solid 1px #000000;
    background-color: #cccccc;
  }

  .material-library {
    min-width: unset;
    padding: 0;
    position: relative;
    right: -11px;
    height: 30px !important;
    cursor: pointer !important;

    & * {
      cursor: pointer !important;
    }

    svg {
      color: #fff !important;
      font-size: 18px;
      margin-right: 0;
    }
  }
}

.materials-library-model {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .materials {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: $component-padding;
    align-items: center;
    justify-content: center;

    .pattern {
      margin: 10px;
      border-radius: $box-border-radius;
      background-color: #cccccc;
      overflow: hidden;

      &:hover {
        background: $app-primary;
      }

      &.selected {
        background: $clickable-gradient;

        &:hover {
          background: $clickable-hover-gradient;
        }
      }
    }
  }

  & > .pager {
    padding: $component-padding;
    border-top: solid 1px $horizontalRule-Background;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.conversion-unit {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;

  .conversion-list {
    flex: 1;
  }

  .conversion-item-style {
    margin-top: 0px !important;
    flex: 1;
    margin-left: 2px;
    margin-right: 2px;
    background-color: rgba(255, 255, 255, 0.844);
  }

  .conversion-item-flex {
    margin-top: 0px !important;
    flex: 0 0 120px;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 10px;
  }

  .h-app-multi-item-field .multi-item-component-wrapper button {
    flex: 0 0 120px !important;
  }

  .h-app-input-field {
    margin-top: 0px !important;
  }

  .h-app-multi-item-field {
    margin-top: 0px !important;
  }
}

.Mui-disabled {
  pointer-events: auto;
}

.conversion-expand {
  flex: 1 !important;
}

.unit-conversion-builder {
  .h-app-multi-item-field button {
    margin-top: 0px !important;
  }
}

.conversion-button {
  height: 40px;
  margin-right: 10px !important;
}

.conversion-calculate-button {
  width: 96%;
  margin-bottom: 20px !important;
}

@import "../../styles/variables";

.h-app-login {
  align-self: center;
  justify-self: center;
  max-width: 340px;
  padding: $page-padding;
  width: 100%;
  text-align: right;
  box-shadow: $layer-shadow;
  border-radius: $box-border-radius;
  background: $page-Background;
  border-top: solid 10px $app-primary;

  .h-app-logo {
    margin-bottom: $page-padding + 15px;
  }

  .message {
    text-align: left;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
    color: green;
    
    &.error {
      color: red;
    }
  }

  .verificationMessage{
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 15px;
  }

  .credentials {
    background: $item-Background;
    color: $item-Foreground;
    border: solid 3px transparent;
    padding: 0 20px 25px;
    box-shadow: $layer-shadow;
    border-radius: $box-border-radius;
    margin-bottom: $page-padding;
    transition: border 0.2s;
  }

  &.error-highlight {
    .credentials {
      border: solid 3px $error-Background;
    }
  }

  &.in {
    opacity: 0;
    animation: scale-pop-in 0.5s 0.5s forwards;
  }

  &.error {
    animation: shake 0.5s forwards linear;
  }

  &.pulsate-login {
    animation: pulse 1.5s infinite;
  }
}

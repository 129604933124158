@import "../../styles/variables";

.h-app-toolbar {
  display: flex;
  flex-direction: row;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  padding: 0 $component-padding $app-logo-margin $component-padding;
  box-sizing: border-box;
  margin: 0 0 0 ($sideNav-width);
  background: $toolbar-Background;
  color: $toolbar-Foreground;
  flex: none;
  transition: margin-left 0.2s;
  min-height: 58px;
  z-index: 1;

  .splitter {
    flex: 0 0 1px;
    display: block;
    background-color: $horizontalRule-Background;
    margin: 0 $component-padding;
    opacity: 0;
    animation: tool-button-in 0.4s 0.2s forwards;
  }

  &:empty {
    display: none;
  }

  .h-app-search-box + .h-app-tool-button {
    margin-left: $component-padding;
  }

  .h-app-tool-component {
    padding: $component-padding !important;
    animation: tool-button-in 0.4s 0.2s forwards;
    opacity: 0;
  }

  .h-app-children-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    & > .h-app-tool-button {
      height: 100%;
    }

    & > .h-app-tool-children {
      position: absolute;
      top: 100%;
      background: $library-Background;
      box-shadow: $layer-shadow;
      border-radius: $box-border-radius;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transform: translateY(-20px);
      transition: all 0.2s 0.2s;

      .h-app-tool-button {
        border-radius: 0px !important;

        &:hover {
          background: $library-item-hover-Background;
          color: $library-item-hover-Foreground;
        }
      }
    }
  }

  .h-app-tool-button {
    padding: $component-padding !important;
    border-radius: $box-border-radius;
    margin: 0;
    transition: all 0.2s;
    background: $button-secondary-Background;
    color: $button-secondary-Foreground;
    opacity: 0;
    animation: tool-button-in 0.4s 0.2s forwards;
    border: none;
    white-space: nowrap;

    &:hover,
    &:focus {
      background: $button-secondary-hover-Background;
      color: $button-secondary-hover-Foreground;
    }

    &:focus {
      & + .h-app-tool-children {
        max-height: 200px;
        opacity: 1;
        transform: none;
        transition: all 0.2s 0s;
      }
    }

    svg {
      margin-right: $component-gutter;
      font-size: 16px;
      margin-top: -2px;
      color: $toolbar-item-icon-Foreground;
      width: 20px;

      &.children-dropdown {
        margin-left: 5px;
        margin-right: 0;
        padding-top: 2px;
        font-size: 12px;
        border-left: solid 1px $horizontalRule-Background;
      }
    }

    &.primary {
      background: $button-primary-Background;
      color: $button-primary-Foreground;
      animation: fade-in 0.4s 0.2s forwards;

      svg {
        color: $button-primary-Foreground;
      }

      &:hover,
      &:focus {
        background: $button-primary-hover-Background;
        color: $button-primary-hover-Foreground;
      }

      &.Mui-disabled {
        background: $button-primary-disabled-Background;
        color: $button-primary-disabled-Foreground;
        cursor: no-drop;

        svg {
          color: $button-primary-disabled-Foreground;
        }
      }
    }

    &.raised {
      box-shadow: $layer-shadow;

      &:active {
        box-shadow: none;
        transform: translateY(3px);
      }
    }

    &.Mui-disabled {
      background: $button-secondary-disabled-Background;
      color: $button-secondary-disabled-Foreground;
      cursor: no-drop;

      svg {
        color: $button-secondary-disabled-Foreground;
      }
    }
  }
}

.h-app-side-nav.collapsed + .h-app-breadcrumb-trail + .h-app-toolbar {
  margin-left: 15px + $component-padding;
}

@keyframes tool-button-in {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

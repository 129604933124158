@import "../../styles/variables";

.measurables-select {
  width: 100%;
}

.descriptors-select {
  width: 100%;
}

.instructions-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;

  .instructions-field {
    width: 100%;
    margin-top: 0 !important;
  }
}

.measureable-checkbox-items {
  margin-top: -20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

@import "../../styles/variables";

$alertCoverColor: rgba(0, 0, 0, 0.5);
$alertTransitionTime: 0.3s;

.h-app-alert-cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  animation: alert-cover-in $alertTransitionTime forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s;

  &.has-modal {
    filter: blur(10px);
  }

  .h-app-alert {
    min-width: 400px;
    width: 25%;
    max-height: 90%;
    background: $modal-window-Background;
    box-shadow: $layer-shadow;
    border-radius: $box-border-radius;
    animation: alert-in $alertTransitionTime forwards;
    display: flex;
    flex-direction: column;

    .alert-body {
      flex: 100;
      margin: $page-padding;
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: pre-wrap;

      svg {
        font-size: 30px;
        flex: 0 0 30px;
        margin-top: 4px;
        margin-right: $component-padding;

        &.info {
          color: $info-Background;
        }

        &.warning,
        &.question {
          color: $warning-Background;
        }

        &.error {
          color: $error-Background;
        }
      }
    }

    .alert-actions {
      flex: 1;
      min-height: 40px;
      text-align: right;
      margin: $component-padding $page-padding $component-padding 0;

      & > * {
        margin-left: $component-gutter;
      }
    }
  }

  &.closing {
    animation: alert-cover-out $alertTransitionTime forwards;

    .h-app-alert {
      animation: alert-out $alertTransitionTime forwards;
    }
  }
}

@keyframes alert-cover-in {
  0% {
    background: transparent;
  }
  100% {
    background: $alertCoverColor;
  }
}

@keyframes alert-cover-out {
  0% {
    background: $alertCoverColor;
  }
  100% {
    background: transparent;
  }
}

@keyframes alert-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes alert-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-pop-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  12% {
    transform: translateX(-10px);
  }
  26% {
    transform: translateX(0);
  }
  38% {
    transform: translateX(10px);
  }
  51% {
    transform: translateX(0);
  }
  63% {
    transform: translateX(-10px);
  }
  76% {
    transform: translateX(0);
  }
  88% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(15px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate((360deg));
  }
}

@keyframes pulse {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #eee;
  }
  100% {
    background-color: #ccc;
  }
}

@keyframes move-flash {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: rgba(210, 190, 0, 0.2);
  }
  100% {
    background-color: transparent;
  }
}
